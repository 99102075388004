import React from "react";
import { Chip } from "@material-ui/core";

function WarningChip(props) {
    const {
        message,
      } = props;
  
  return (
    <Chip style={{
        backgroundColor: "#FFE1DB",
        color: "#db2828",
      }}
      label={message}
      size="small" />
  );
}

export default WarningChip;
