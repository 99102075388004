import firebase from "../config/fbConfig";
import axios from "axios";
import {
  errorMsg,
  stopWaitMsg,
  successMsg,
  waitingMsg,
} from "../constants/messageHandlers";

export const onLogin = (email, pass) => (setState, state) => {
  setState({
    login: {
      ...state.login,
      isRequesting: true,
      error: "",
      user: {},
      isLoggedIn: false,
    },
  });
  let loggedInUser, details;
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .then(({ user }) => {
      loggedInUser = user;
      return user.getIdToken();
    })
    .then((idToken) => {
      // assigning token
      details = {
        email: loggedInUser.email,
        uid: loggedInUser.uid,
        token: idToken,
      };
      axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
      return axios.get("/auth/customClaims");
    })
    .then((res) => {
      let isResetted = false;
      if (res.data.customClaims) {
        isResetted = res.data.customClaims.isResetted;
      }
      return setState({
        login: {
          ...state.login,
          isResetted: isResetted,
          isRequesting: false,
          isLoggedIn: true,
          error: "",
          user: details,
          isAdmin: res.data?.customClaims?.role === "admin" ? true : false,
        },
      });
    })
    .catch((err) => {
      console.error(err);
      if (
        err.code === "auth/wrong-password" ||
        err.code === "auth/user-not-found"
      )
        alert("Incorrect username or password.");
      else alert(err.message);
      setState({
        login: {
          ...state.login,
          isRequesting: false,
          error: "Failed to login",
          user: {},
          isLoggedIn: false,
        },
      });
    });
};

export const requestCompanies = () => (setState, state) => {
  setState({
    companies: {
      isLoading: true,
      data: [],
      error: "",
    },
  });
  return axios
    .get("/companies/load?email=" + state.login.user.email)
    .then((res) => {
      return setState({
        companies: {
          isLoading: false,
          data: res.data.companies,
          error: "",
        },
      });
    })
    .catch((err) => {
      console.error(err);
      errorMsg(err.response.data.message);
      setState({
        companies: {
          isLoading: false,
          data: [],
          error: "Failed to make companies request",
        },
      });
    });
};

export const checkAuth = () => (setState, state) => {
  firebase.auth().onAuthStateChanged((user) => {
    let details = {};
    if (user) {
      user
        .getIdToken()
        .then((idToken) => {
          details = {
            email: user.email,
            uid: user.uid,
            token: idToken,
          };
          axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
          return axios.get("/auth/customClaims");
        })
        .then((res) => {
          let isResetted = false;
          if (res.data.customClaims) {
            isResetted = res.data.customClaims.isResetted;
          }
          return setState({
            login: {
              ...state.login,
              isResetted: isResetted,
              checkingAuth: false,
              isRequesting: false,
              isLoggedIn: true,
              error: "",
              user: details,
              isAdmin: res.data?.customClaims?.role === "admin" ? true : false,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      return setState({
        login: {
          ...state.login,
          isRequesting: false,
          error: "",
          user: {},
          isLoggedIn: false,
          checkingAuth: false,
        },
      });
    }
  });
};

export const logOut = () => (setState, state) => {
  return firebase
    .auth()
    .signOut()
    .catch((err) => {
      console.error(err);
      errorMsg(err.response.data.message);
    });
};

export const onChangePassword = (password) => (setState, state) => {
  setState({
    changePassword: {
      ...state.changePassword,
      isProcessing: true,
    },
  });
  waitingMsg("Changing password...");
  return axios
    .put("/auth/changepassword", { password })
    .then((res) => {
      stopWaitMsg();
      successMsg(res.data.message);
      setState({
        changePassword: {
          ...state.changePassword,
          isProcessing: false,
        },
      });
      return window.location.reload();
    })
    .catch((err) => {
      console.error(err);
      stopWaitMsg();
      errorMsg(err.response.data.message);
      setState({
        changePassword: {
          ...state.changePassword,
          isProcessing: false,
        },
      });
    });
};

export const onResetPassword = (email, password) => (setState, state) => {
  setState({
    resetPassword: {
      ...state.resetPassword,
      isProcessing: true,
    },
  });
  return axios
    .put(`/auth/resetpassword?email=${email}`, { password })
    .then((res) => {
      stopWaitMsg();
      successMsg(res.data.message);
      return setState({
        resetPassword: {
          ...state.resetPassword,
          isProcessing: false,
        },
      });
    })
    .catch((err) => {
      console.error(err);
      stopWaitMsg();
      errorMsg(err.response.data.message);
      setState({
        resetPassword: {
          ...state.resetPassword,
          isProcessing: false,
        },
      });
    });
};

export const onResetTempPassword = (password) => (setState, state) => {
  setState({
    resetTempPassword: {
      ...state.resetTempPassword,
      isProcessing: true,
    },
  });
  return axios
    .put("/auth/resetTempPassword", { password })
    .then((res) => {
      stopWaitMsg();
      successMsg(res.data.message);
      setState({
        resetTempPassword: {
          ...state.resetTempPassword,
          isProcessing: false,
        },
      });
      return window.location.reload();
    })
    .catch((err) => {
      console.error(err);
      stopWaitMsg();
      errorMsg(err.response.data.message);
      setState({
        resetTempPassword: {
          ...state.resetTempPassword,
          isProcessing: false,
        },
      });
    });
};

//forgot password

export const forgotPassword = (email) => (setState, state) => {
  setState({
    forgotPassword: {
      ...state.forgotPassword,
      isProcessing: true,
    },
  });
  waitingMsg("Sending email ...")
  return firebase.auth().sendPasswordResetEmail(email)
  // return axios
  //   .post(`/auth/forgotpassword/?email=${email}`)
    .then((res) => {
      stopWaitMsg();
      // successMsg(res.data.message);
      successMsg("Email sent successfully");
      setState({
        forgotPassword: {
          ...state.forgotPassword,
          isProcessing: false,
        },
      });
    })
    .catch((err) => {
      console.error(err);
      stopWaitMsg();
      // errorMsg(err.response.data.message);
      errorMsg("Failed to send email")
      setState({
        forgotPassword: {
          ...state.forgotPassword,
          isProcessing: false,
        },
      });
    });
};

export const loadAllCompanyEmployees = async () => {
  try {
    const res = await axios.get("/companies/loadAllCompaniesEmployees");
    return res.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const loadAllCompanyClients = async () => {
  try {
    const res = await axios.get("/companies/loadAllCompaniesClients");
    return res.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const loadAllCompanyPlacements = async () => {
  try {
    const res = await axios.get("/companies/loadAllCompaniesPlacements");
    return res.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
export const onChangeEmail = (oldEmail, newEmail, cb) => (setState, state) => {
  setState({
    changeEmail: {
      ...state.changeEmail,
      isProcessing: true,
    },
  });
  waitingMsg("Changing email...");
  return axios
    .put("/auth/updateEmail", { oldEmail, newEmail })
    .then((res) => {
      stopWaitMsg();
      successMsg(res.data.message);
      setState({
        changeEmail: {
          ...state.changeEmail,
          isProcessing: false,
        },
      });
      cb();
    })
    .catch((err) => {
      console.error(err);
      stopWaitMsg();
      errorMsg(err.response.data.message);
      setState({
        changeEmail: {
          ...state.changeEmail,
          isProcessing: false,
        },
      });
    });
};
