import React, { useState, useContext } from 'react'
import PasswordDialog from "../../../constants/passwordDialog"
import { onChangePassword } from '../../../context/middleware'
import { Context } from "../../../context/index"

function ChangePassword() {
  const [state, dispatch] = useContext(Context)
  const [componentState, setComponentState] = useState({
    password: "",
    confirmPassword: "",
  })

  const { password } = componentState

  const setState = (obj) => {
    dispatch({
      type: "SET_STATE",
      payload: obj,
    })
  }

  const handleChange = (name, val) => {
    setComponentState({
      ...componentState,
      [name]: val,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    return onChangePassword(password)(setState, state)
  }

  return (
    <PasswordDialog
      {...componentState}
      handleChange={handleChange}
      handleSubmit={onSubmit}
      isProcessing={state.changePassword.isProcessing}
      buttonText={"Change Password"}
    />
  )
}

export default ChangePassword
