import React, { useState, useContext } from "react";
import { logOut, onChangeEmail } from "../../../context/middleware";
import { Context } from "../../../context/index";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getQueryParameter } from "../../../constants/utils";

function ChangeEmail() {
	const [state, dispatch] = useContext(Context);

	const oldEmail = getQueryParameter("email")

	const [componentState, setComponentState] = useState({
		oldEmail: oldEmail,
		newEmail: "",
		confirmNewEmail: "",
	});

	const [errors, setErrors] = useState({
		oldEmail: "",
		newEmail: "",
		confirmNewEmail: "",
	});

	const setState = (obj) => {
		dispatch({
			type: "SET_STATE",
			payload: obj,
		});
	};

	const handleChange = (name, val) => {
		setComponentState({
			...componentState,
			[name]: val,
		});
	};

	const validate = () => {
		const { oldEmail, newEmail, confirmNewEmail } = componentState;
		const errors = {};
		if (oldEmail === "") {
			errors.oldEmail = "Old email is required";
		}
		if (newEmail === "") {
			errors.newEmail = "New email is required";
		}
		if (confirmNewEmail === "") {
			errors.confirmNewEmail = "Confirm new email is required";
		}

		if (newEmail !== confirmNewEmail) {
			errors.confirmNewEmail = "New email and confirm new email must match";
		}

		setErrors(errors);
		return errors;
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const errors = validate();
		if (Object.keys(errors).length > 0) {
			return;
		}
		const { oldEmail, newEmail } = componentState;
		return onChangeEmail(oldEmail, newEmail, callback)(setState, state);
	};

	const callback = () => {
		window.history.go(-1)
	};

	return (
		<Container className="px-2">
			<Row>
				<Col xs={12}>
					<h1>Change Email</h1>
				</Col>
				<Col xs={12}>
					<Form onSubmit={onSubmit}>
						<Form.Group className="mb-3" controlId="oldEmail">
							<Form.Label>Old Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter old email"
								value={componentState.oldEmail}
								isInvalid={errors.oldEmail}
								readOnly
							/>
							<Form.Control.Feedback type="invalid">
								{errors.oldEmail}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3" controlId="newEmail">
							<Form.Label>New Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter new email"
								value={componentState.newEmail}
								onChange={(e) => handleChange("newEmail", e.target.value)}
								isInvalid={errors.newEmail}
								required
							/>
							<Form.Control.Feedback type="invalid">
								{errors.newEmail}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3" controlId="confirmNewEmail">
							<Form.Label>Confirm New Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Confirm new email"
								value={componentState.confirmNewEmail}
								onChange={(e) =>
									handleChange("confirmNewEmail", e.target.value)
								}
								isInvalid={errors.confirmNewEmail}
								required
							/>
							<Form.Control.Feedback type="invalid">
								{errors.confirmNewEmail}
							</Form.Control.Feedback>
						</Form.Group>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
}

export default ChangeEmail;
