import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import { FaAddressCard, FaPhoneAlt } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { MdLocationCity } from "react-icons/md";

import { differenceInDays, isBefore } from "date-fns";
import { Avatar, Badge, Typography } from "@material-ui/core";
import EmployeeNotes from "../shared/components/EmployeeNotes";
import InactiveChip from "../shared/lib/Chips/InactiveChip";
import ActiveChip from "../shared/lib/Chips/ActiveChip";
import RedChip from "../shared/lib/Chips/RedChip";
import WarningChip from "../shared/lib/Chips/WarningChip";
import FlairTable from "../shared/FlairTable";
import { CalendarFormatter } from "../shared/CalendarFormatter";
import { loadAllCompanyEmployees } from "../../context/middleware";

export default function AllEmployees() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [employeelist, setEmployeeList] = React.useState([]);
    const [placements, setPlacements] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const [placements_settings_list, setPlacementsSettingsList] = React.useState([]);
    const [i9Form, setI9Form] = React.useState([]);


    const fetchAllEmployees = async () => {
        try {
            setIsLoading(true);
            const data = await loadAllCompanyEmployees()
            setEmployeeList(data?.employees || []);
            setPlacements(data?.placements || []);
            setClients(data?.clients || []);
            setPlacementsSettingsList(data?.clientsSettings || []);
            setI9Form(data?.dynamicProfileData?.sections?.['i-9form']?.fields?.filter((field) => field.name === "status")?.[0]?.values || []);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchAllEmployees()
    }, [])


    const getEndClientsForEmployee = (employeeId) => {
        const filteredPlacements = placements.filter((placement) => placement.employeeID === employeeId);

        const endClientIds = filteredPlacements.flatMap((placement) => {
            const clientDetails = placements_settings_list.find((c) => c.placementID === placement.id);
            if (!clientDetails) return [];
            const clients = Object.values(clientDetails.clients || {});
            return clients
                .filter((client) => client.clientType === "End Client")
                .map((client) => client.clientId);
        });

        const endClientNames = endClientIds.map((clientId) => {
            const endClient = clients.find((client) => client.clientId === clientId);
            return endClient ? endClient.businessName : "";
        });

        return endClientNames;
    };

    const columns = [
        {
            title: "Name",
            field: "name",
            width: 250,
            render: (rowData) => {
                return (
                    <Typography>
                        <table style={{ width: 250 }}>
                            <tr>
                                <td>
                                    <Avatar
                                        style={{ width: 24, height: 24, backgroundColor: "red" }}
                                        src={rowData?.imageURL}
                                    />
                                </td>
                                <td>&nbsp;</td>
                                <td style={{ color: "#2c9273", cursor: "pointer" }} onClick={() => {
                                    // open in new tab
                                    window.open(rowData.companyUrl + '/console/employees/' + rowData.employeeid, "_blank")
                                }} >
                                    {rowData?.name}
                                </td>
                            </tr>
                        </table>
                    </Typography>
                );
            },
        },
        {
            title: "Email", field: "email",
            width: 150,
            render: (rowData) => <div style={{ width: 250, paddingLeft: 5 }}>{rowData.email}</div>,
        },
        {
            title: "Phone", field: "phone",
            width: 150,
            render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}><FaPhoneAlt /> {rowData.phone}</div>,
        },
        {
            title: "Branch",
            field: "branch",

            width: 100
        },
        {
            title: "Employee ID",
            field: "employeeid",
            defaultSort: "dsc",
            width: 150,
            render: (rowData) => {
                if (rowData.employeeid)
                    return (
                        <div style={{ width: 130, paddingLeft: 5, textDecoration: "none", color: "#3f51b5" }}>
                            <FaAddressCard size={24} /> <b>{rowData.employeeid}</b>
                        </div>
                    );
                return (
                    <div style={{ width: 130, paddingLeft: 5 }}>

                    </div>
                );
            },


        },
        {
            title: "Company Name",
            field: "companyName",

            width: 100,
        },
        {
            title: "End Client",
            field: "getEndClientsForEmployee",
            render: (rowData) => {
                const endClients = getEndClientsForEmployee(rowData.employeeid, placements, clients);
                return (
                    <div>
                        {endClients.map((client, index) => (
                            <div key={index}>{client}</div>
                        ))}
                    </div>
                );
            },


            filtering: false,

        },
        {
            title: "Date of Joining",
            field: "joiningDate",
            width: 140,
            render: (rowData) => <div style={{ paddingLeft: 5 }}>{rowData.joiningDate}</div>,

        },
        { title: "Work Authorization", width: 170, field: "workauth" },
        { title: "Work authorization start date", field: 'workAuthStartDate', width: 240 },
        { title: "Work authorization end date", field: 'workAuthEndDate', width: 240 },
        {
            title: "Work Authorization Expiry",
            field: "workauthExp",
            width: 200,

            render: (rowData) => {
                const remainingDaysString = rowData.workauthExp;

                return (
                    <div align="center" style={{ paddingLeft: 5 }}>
                        {remainingDaysString ? (
                            <WarningChip message={remainingDaysString} />
                        ) : (
                            <span></span>
                        )}
                    </div>
                );
            },

        },
        { title: "I9", width: 100, field: "i9form" },
        { title: "E-Verify", width: 100, field: "everify" },
        {
            title: "Reporting manager",
            field: "reportingmanager",
            width: 200,
            render: (rowData) => {
                if (rowData.reportingmanager)
                    return (<div style={{ paddingLeft: 5 }}>
                        <table>
                            <tr>
                                <td><FcManager size={24} /></td>
                                <td>&nbsp;</td>
                                <td><b>{rowData.reportingmanager}</b></td>
                            </tr>
                        </table>
                    </div>);
                return (
                    <div style={{ paddingLeft: 5 }}>

                    </div>
                );
            }
            ,

        },
        {
            title: "Total Placements",
            field: "totalPlacements",

            width: 130,
            render: (rowData) => {
                return (
                    <div align="center">
                        <Badge
                            color="primary"
                            showZero={false}
                            badgeContent={rowData.totalPlacements}
                        />
                    </div>
                );
            },

        },
        {
            title: "Active Placements",
            field: "activePlacements",

            width: 130,
            // hidden: activePlacementsState,
            render: (rowData) => {
                return (
                    <div align="center">
                        <Badge
                            color="primary"
                            showZero={false}
                            badgeContent={rowData.activePlacements}
                        />
                    </div>
                );
            },

        },
        {
            title: "Employee status",
            field: "employeeWorkStatus",

            width: 150,
            lookup: {
                0: "Bench",
                1: "Working",
                2: "Training",
                3: "Prospect"
            },

        },
        {
            title: "Job title", field: "jobtitle",
            width: 150,
            render: (rowData) => <div style={{ paddingLeft: 5 }}>{rowData.jobtitle}</div>,

        },
        {
            title: "Department",
            field: "department",

            width: 150,
            lookup: {
                0: "Java",
                1: "DevOps/Cloud",
                2: "Networking/Security",
                3: "Python",
                4: "QA",
                5: ".Net",
                6: "Data Science",
                7: "Big Data",
                8: "CRM",
                9: "Legal",
                10: "HR",
                11: "Accounts",
                12: "Bench sales",
            },

        },
        {
            title: "Employee Home Address",
            field: "employeeHomeAddress",

            width: 200,
            // hidden: true,
            // hiddenByColumnsButton: true,
            render: (rowData) => {
                if (rowData.employeeHomeAddress) {
                    if (rowData.employeeHomeAddress !== "") {
                        return (
                            <div style={{ width: 400, paddingLeft: 5 }}>
                                <table>
                                    <tr>
                                        <td><MdLocationCity size={24} /></td>
                                        <td>&nbsp;</td>
                                        <td>{rowData.employeeHomeAddress}</td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div align="center" style={{ width: 130, paddingLeft: 5 }}>
                            --
                        </div>
                    );
                }
            },
        },
        {
            title: "Category",
            field: "category",
            width: 100,

            align: "center",
        },
        {
            title: "Payroll ID",
            field: "payrollid",
            width: 100,

            // hidded: payrollState,
            render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.payrollid}</div>,
        },
        {
            title: "Notes",
            field: "empnotesIndexforLookup",

            width: 80,
            align: "center",
            lookup: {
                0: "Notes Available",
                1: "Notes Not Available",
            },
            render: (rowData) => {
                return <div align="center"><EmployeeNotes rowData={rowData} fetchActiveEmployees={() => { }} /></div>
            },

        },
        {
            title: "Status",
            field: "status",

            width: 100,
            lookup: {
                0: "Inactive",
                1: "Active",
                2: "Suspended",
                3: "OnHold",
            },
            render: (rowData) => {
                if (rowData.status === 1)
                    return (
                        <ActiveChip message="Active" />
                    );
                else if (rowData.status === 2)
                    return (
                        <RedChip message="Exit" />
                    );
                else if (rowData.status === 3)
                    return (
                        <Chip
                            style={{
                                width: "100%",
                                backgroundColor: "#fcb532",
                                color: "white",
                            }}
                            label="OnHold"
                            size="small"
                        />
                    );
                return (
                    <InactiveChip message="Inactive" />
                );
            },
            customFilterAndSearch: (value, rowData) => {
                if (value.length === 0) return true;
                return value.includes(rowData.status.toString());
            },

        }
    ];

    let data = [];
    let _employee_list = employeelist.length && employeelist.filter(e => e.type === undefined || e.type !== "staff")
    _employee_list.length &&
        _employee_list.forEach((employee) => {

            const requiredWorkAuth = (() => {
                const workauthArray = employee.workauth;
                if (!workauthArray) {
                    return {};
                }
                const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
                if (activeWorkAuth) {
                    return activeWorkAuth;
                }
                if (!employee.workauth || !employee.inactiveWorkAuthData) {
                    return {};
                }
                const matchingWorkAuth = workauthArray.find((auth) => auth.id === employee.inactiveWorkAuthData.id);

                return matchingWorkAuth;
            })()

            const getTotalPlacements = () => placements.filter(p => p.employeeID === employee.uid && p.draft === false).length || 0
            const getActivePlacements = () => placements.filter(p => p.employeeID === employee.uid && p.draft === false && isBefore(new Date(), new Date(p.endDate)) && !(p?.closingReason?.length > 0)).length || 0
            const totalPlacements = getTotalPlacements()
            const activePlacements = getActivePlacements()
            // const getEmployeeStatus = () => {
            //   if (totalPlacements === 0) {
            //     return employeeStatusList.indexOf("Training")
            //   } else if (totalPlacements > 0 && activePlacements > 0) {
            //     return employeeStatusList.indexOf("Working")
            //   } else {
            //     return employeeStatusList.indexOf("Bench")
            //   }
            // }

            const getEmployeeAddress = (employeeAddress) => {
                return [employeeAddress?.line1,
                employeeAddress?.line2,
                employeeAddress?.city,
                employeeAddress?.state,
                employeeAddress?.country,
                employeeAddress?.zip].filter((i) => i).join(', ')
            }

            data.push({
                //name: employee?.personal?.firstname + employee?.personal?.lastname,
                name: [
                    employee?.personal?.firstname?.trim(),
                    employee?.personal?.middlename?.trim(),
                    employee?.personal?.lastname?.trim(),
                ]
                    .filter((x) => x)
                    .join(" "),
                email: employee?.email,
                phone: employee?.personal?.phonenumber,
                branch: employee?.personal?.branch,
                employeeid: employee?.uid,
                reportingmanager: employee.reportingmanager,
                // employeestatus: getEmployeeStatus(),
                usertype: employee?.Role,
                employeeWorkStatus: employeeStatusList.indexOf(
                    employee.employeeStatus
                ),
                jobtitle: employee?.personal?.jobtitle,
                department: departmentList.indexOf(employee?.personal?.department),
                status: statusList.indexOf(employee?.status),
                activePlacements: activePlacements,
                totalPlacements: totalPlacements,
                employeeHomeAddress: getEmployeeAddress(employee?.mailingaddress),
                joiningDate: CalendarFormatter.standardDateFormat(
                    employee?.personal?.dateofjoining
                ),
                workauth: (() => {
                    const workauthArray = employee.workauth;
                    if (!workauthArray) {
                        return '';
                    }
                    const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
                    if (activeWorkAuth) {
                        return activeWorkAuth.work_type;
                    }
                    if (!employee.workauth || !employee.inactiveWorkAuthData) {
                        return '';
                    }
                    const matchingWorkAuth = workauthArray.find((auth) => auth.id === employee.inactiveWorkAuthData.id);
                    if (matchingWorkAuth) {
                        return matchingWorkAuth.work_type;
                    }
                    return '';
                })(),

                workauthExp: (() => {
                    if (!employee.workauth || !employee.inactiveWorkAuthData) {
                        return '';
                    }
                    const workauthArray = employee.workauth;
                    const inactiveWorkAuthData = employee.inactiveWorkAuthData;
                    const activeWorkAuth = workauthArray.find((auth) => auth.active === 'Y');
                    const matchingWorkAuth = workauthArray.find(
                        (auth) => auth.id === inactiveWorkAuthData.id
                    );
                    if (activeWorkAuth) {
                        return '';
                    }
                    else if (matchingWorkAuth) {
                        const presentDate = new Date();
                        const expiryDate = new Date(matchingWorkAuth.work_exp);
                        const daysDiff = differenceInDays(expiryDate, presentDate);
                        const remainingDaysString = ` ${Math.abs(daysDiff).toString()} days`;
                        return remainingDaysString;
                    }
                })(),
                workAuthStartDate: requiredWorkAuth?.work_issue ?? '',
                workAuthEndDate: requiredWorkAuth?.work_exp ?? '',
                i9form: employee?.['i-9form']?.find((item) => {
                    return item?.status === i9Form?.[0] // active
                })?.initiatedon,
                everify: employee?.['e-verification']?.[0]?.['e-verifeddate'],
                category: employee?.personal?.category,
                payrollid: employee?.personal?.payrollid,
                empnotesIndexforLookup: employee?.personal?.employeenotes ? 0 : 1,
                employeenotes: employee?.personal?.employeenotes,
                type: employee?.type,
                companyName: employee?.companyDetails?.companyName,
                companyUrl: employee?.companyDetails?.url,
                imageURL: employee?.imageURL,
            });
        });

    return (
        <div>
            <FlairTable
                title="Employees Report"
                columns={columns}
                data={data}
                isLoading={isLoading}
            />
        </div>
    );
}


export const departmentList = [
    "Java",
    "DevOps/Cloud",
    "Networking/Security",
    "Python",
    "QA",
    ".Net",
    "Data Science",
    "Big Data",
    "CRM",
    "Legal",
    "HR",
    "Accounts",
    "Bench sales",
];

export const employeeStatusList = ["Bench", "Working", "Training", "Prospect"];

export const statusList = ["inactive", "active", "suspended", "onHold"];