import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    background: "linear-gradient(45deg, #280071 10%, #c42053 90%)",
  },
  logoGradient: {
    width: "100%",
    marginTop: "20%",
    color: "#fff",
    marginLeft: "10px",
    height: "50%",
  },
  logoCopyright: {
    color: "#fff",
    fontSize: "14px",
    textAlign: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    height: "32vh",
    marginRight: "50px",
    marginBottom: "50px",
    width: "20vw",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
    background: "#280071",
    "&:hover": {
      background: "#c42053",
    },
  },
}))

export default useStyles
