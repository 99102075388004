import React, { useState, useContext } from "react"
import {
  CssBaseline,
  Paper,
  Box,
  Grid
} from "@material-ui/core"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons"
import useStyles from "../../../styles/loginStyles"
import { Context } from "../../../context/index"
import { onLogin } from "../../../context/middleware"
import { AiOutlineMail } from "react-icons/ai"
import { BiLockAlt } from "react-icons/bi"
import FormInput from "../../../constants/FormInput"
import { Button, Col, Row } from 'react-bootstrap';
import usePassword from "../ForgotPassword"
import { flair_logo } from "../../../constants/values"

function Copyright() {
  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {"Copyright © "}
          flairtechno.com {new Date().getFullYear()}
          {". "}
        </p>
      </Col>
    </Row>
  )
}

export default function Presentation(props) {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [state, dispatch] = useContext(Context)
  const { render, setPasswordDialog, isPasswordDialogOpen } = usePassword()

  const setState = (obj) => {
    dispatch({
      type: "SET_STATE",
      payload: obj
    })
  }

  const _on_login = () => {
    onLogin(email, password)(setState, state)
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={"onleft"}>
        <img
          src={flair_logo}
          alt="Company Logo"
          className={classes.logoGradient}
        />
        {/* <p className={classes.logoCopyright}>
          {"© "}
          {new Date().getFullYear()}
          {"FLAIR - All rights reserved"}
        </p> */}
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
        <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'space-between' }}>
            <div style={{ height: window.innerHeight * 0.2 + "px" }} />
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault()
                _on_login()
              }}
              style={{ width: "100%" }}
            >
              <div className="text-left" >
                <h6 className="h5 mb-0 mt-3">{('Welcome back!')}</h6>
                <p className="text-muted mt-1 mb-4">
                  {('Enter your email address and password to access workforce panel.')}
                </p>
              </div>
              <FormInput
                type="email"
                name="email"
                label={('Email Address')}
                startIcon={<AiOutlineMail size={22} />}
                placeholder={('Enter your email address')}
                containerClass={'mb-3'}
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
              {isPasswordDialogOpen ? render() : null}
              <div class="password-container">
                <FormInput
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label={('Password')}
                  startIcon={<BiLockAlt size={22} />}
                  placeholder={('Enter your Password')}
                  action={
                    <span onClick={() => setPasswordDialog(true)} style={{ cursor: "pointer" }} className="float-end text-muted text-unline-dashed ms-1">
                      {('Forgot your password?')}
                    </span>
                  }
                  onChange={(event) => setPassword(event.target.value)}
                  containerClass={'mb-3'}></FormInput>
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </span>
              </div>
              <FormInput
                type="checkbox"
                name="checkbox"
                label={('Remember me')}
                containerClass={'mb-3'}
                defaultChecked
              />
              <div className="mb-3 text-center d-grid">
                <Button style={{ backgroundColor: "#2c9273", border: "1px solid transparent" }} type="submit" disabled={state.login.isRequesting}>
                  {state.login.isRequesting ? "Signing in" : "Sign In"}
                </Button>
              </div>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
            <div style={{ height: window.innerHeight * 0.2 + "px" }} />
          </div>

        </div>
      </Grid>
    </Grid>
  )
}
