import React, { useContext } from "react";
import {
	Avatar,
	AppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	useTheme,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CssBaseline,
} from "@material-ui/core";
import navStyles from "../styles/navbarStyles";
import { Link, useLocation } from "react-router-dom";
import { flair_logo } from "../constants/values";
import clsx from 'clsx';
import { Business, ChevronLeft, ChevronRight, People } from "@material-ui/icons"
import { Context } from "../context"
import MenuIcon from '@material-ui/icons/Menu';

export default function Navbar({ children }) {
	const classes = navStyles();
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const [state, dispatch] = useContext(Context)
	const location = useLocation()

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openIcon = Boolean(anchorEl);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<img src={flair_logo} alt="" height="60" />
					<div className={classes.align}>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<Avatar alt="Remy Sharp" src={""} />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							keepMounted
							transformOrigin={{
								vertical: "center",
								horizontal: "right",
							}}
							open={openIcon}
							onClose={handleClose}
						>
							<Link
								to="/signout"
								style={{ textDecoration: "none", color: "black" }}
								onClick={handleClose}
							>
								<MenuItem>Sign Out</MenuItem>
							</Link>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
				open={open}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
					</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem
						component={Link}
						to="/"
						selected={location.pathname === "/"}
					>
						<ListItemIcon>
							<Business />
						</ListItemIcon>
						<ListItemText primary={"My Companies"} style={{ color: "black" }} />
					</ListItem>
					{state.login.isAdmin ? (
						<ListItem
							component={Link}
							to="/all-employees"
							selected={location.pathname === "/all-employees"}
						>
							<ListItemIcon>
								<People />
							</ListItemIcon>
							<ListItemText
								primary={"All Employees"}
								style={{ color: "black" }}
							/>
						</ListItem>
					) : null}
				</List>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{children}
			</main>
		</div>
	);
}
