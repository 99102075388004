import AllEmployees from "./components/AllEmployees";
import ChangeEmail from "./components/Authentication/ChangeEmail";
import ChangePassword from "./components/Authentication/ChangePassword";
import Login from "./components/Authentication/Login";
import ResetPassword from "./components/Authentication/ResetPassword";
import SignOut from "./components/Authentication/SignOut";
import DashboardWrapper from "./components/DashboardWrapper";

export const unProtectedRoutes = [
	{
		path: "/login",
		component: Login,
	},
];

export const protectedRoutes = [
	{
		path: "/",
		component: DashboardWrapper,
	},
	{
		path: "/resetpassword/:email",
		component: ResetPassword,
	},
	{
		path: "/changepassword",
		component: ChangePassword,
	},
	{
		path: "/changeemail",
		component: ChangeEmail,
	},
	{
		path: "/signout",
		component: SignOut,
	},
	{
		path: "/all-employees",
		component: AllEmployees,
	},
];
