import { Backdrop, Grid } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { Context } from "../../context"
import CustomCard from './CustomCard'
import { requestCompanies } from '../../context/middleware'
import { Skeleton } from "@material-ui/lab";

function MyCompanies() {
    const [state, dispatch] = useContext(Context)


    const setState = (obj) => {
        dispatch({
            type: "SET_STATE",
            payload: obj,
        })
    }

    useEffect(() => {
        requestCompanies()(setState, state)
    }, [])

    if (state.companies.isLoading) {
        return (
            <div>
                <h3 style={{ textAlign: "center" }}>
                    Welcome {state.login.user.email}, <br />
                </h3>
                <Grid container spacing={2} justify="center">
                <Grid item>
                    <Skeleton animation="wave" variant="rect" width={400} height={350} style={{ margin: '10px' }} />
                </Grid>
                <Grid item>
                    <Skeleton animation="wave" variant="rect" width={400} height={350} style={{ margin: '10px' }} />
                </Grid>
                <Grid item>
                    <Skeleton animation="wave" variant="rect" width={400} height={350} style={{ margin: '10px' }} />
                </Grid>
            </Grid>
            </div>
        )
    }

    return (
        <div>

            <Grid spacing={3} container>
                <Grid item xs={12}>
                    {/* <Paper
                elevation={3}
                className={classes.mainFeaturedPost}
                style={{ backgroundImage: `url(${flair_logo})` }}
              >
                <div className={classes.overlay} />
              </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{ textAlign: "center" }}>
                        Welcome {state.login.user.email}, <br />
                        You have {state.companies.data.length} companies associated to this email.
                    </h3>
                </Grid>
                {state.companies.data.map((company) => {
                    return (
                        <CustomCard
                            key={company.name}
                            imageUrl={company.logo}
                            title={company.name}
                            url={company.url}
                            token={state.login.user.token}
                        />
                    )
                })}
            </Grid>
        </div>
    )
}

export default MyCompanies
