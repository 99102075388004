import React from "react"
import { useIdleTimer } from "react-idle-timer"
import { useHistory } from "react-router"
import MyCompanies from "../Dashboard/MyCompanies"

export default function DashboardWrapper() {
  const history = useHistory()

  const handleOnIdle = (event) => {
    console.log("user is idle", event)
    console.log("last active", getLastActiveTime())
    if (getRemainingTime() === 0) history.push("/signout")
  }

  const handleOnActive = (event) => {
    console.log("user is active", event)
    console.log("time remaining", getRemainingTime())
  }

  const handleOnAction = (event) => {
    console.log("user did something", event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  })

  return (
    <div>
      <MyCompanies />
    </div>
  )
}
