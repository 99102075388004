import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firebase-firestore"
import "firebase/storage"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1tlpIV6QdUb14uokNbqAjyMc5HiG7jrA",
  authDomain: "flair-auth.firebaseapp.com",
  projectId: "flair-auth",
  storageBucket: "flair-auth.appspot.com",
  messagingSenderId: "368058005264",
  appId: "1:368058005264:web:acb324bb9a910f5aa85795",
  measurementId: "G-12MDNR689R"
};

const app = firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()

export { storage, app as default }
