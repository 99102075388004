import { getDay } from "date-fns";
import format from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import appconfig from "../../config/appconfig";

export class CalendarFormatter {
    static standardDateFormat(date) {
        const d1 = new Date(date);
        if (!isNaN(Date.parse(d1))) return format(d1, appconfig.dateformatter);
        return null;
    }

    static customDateFormat(date, formatType = "yyyy-MM-dd") {
        if (!formatType) return "invalid format type";
        const d = new Date(date);
        console.log(
            `CalendarFormatter.customDateFormat() : ${date} - ${formatType}`
        );
        return format(d, formatType);
    }

    static stringToDate(str) {
        const parsed = dateFnsParse(str, appconfig.dateformatter, new Date());
        return !isNaN(Date.parse(parsed)) ? parsed : undefined;
    }

    static getDayFromDate(date) {
        const d = new Date(date);
        const dayIndex = getDay(d);
        switch (dayIndex) {
            case 0:
                return "Sunday";

            case 1:
                return "Monday";

            case 2:
                return "Tuesday";

            case 3:
                return "Wednesday";

            case 4:
                return "Thursday";

            case 5:
                return "Friday";

            case 6:
                return "Saturday";

            default:
                return "";
        }
    }
}
