import React, { useEffect, useContext } from 'react'
import { Context } from '../../../context'
import { logOut } from '../../../context/middleware'


function SignOut() {
  const [state, dispatch] = useContext(Context)

  const setState = (obj) => {
    dispatch({
      type: "SET_STATE",
      payload: obj
    })
  }

  useEffect(() => {
    logOut()(setState, state)
  }, [])
  return (
    <div>
      Processing ...
    </div>
  )
}

export default SignOut
