import React, { useEffect } from "react"
import axios from "axios"
import AuthWrapper from "./components/Authentication/AuthWrapper"
import './assets/scss/Theme.scss';
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    const base_url = window.location.href.includes("http://localhost")
      ? "http://localhost:5001/flair-auth/us-central1/api"
      : "https://us-central1-flair-auth.cloudfunctions.net/api"
    axios.defaults.baseURL = base_url
  }, [])

  return (
    <div>
      <BrowserRouter>
        <AuthWrapper />
      </BrowserRouter>
    </div>
  )
}

export default App
