import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(10),
  },
}))

function PasswordDialog(props) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "90vh" }}
    >
      <Grid item xs={3}>
        <Paper className={classes.form}>
          <Form {...props} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PasswordDialog

function Form(props) {
  const { handleSubmit, buttonText, handleChange, isProcessing } = props
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} direction="column" alignItems="center" justify="center">
        <Grid item xs={12}>
          <TextField
            type={showPassword ? "text" : "password"}
            label="Password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
              handleChange("password", e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            variant="outlined"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value)
              handleChange("confirmPassword", e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {!showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              password === "" ||
              password.length <= 7 ||
              password !== confirmPassword ||
              isProcessing
            }
          >
            {isProcessing ? "Processing.." : buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

Form.propTypes = {
  buttonText: PropTypes.any,
  disabled: PropTypes.any,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.any,
}
