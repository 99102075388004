export default {
  login: {
    isRequesting: false,
    error: "",
    user: {},
    isLoggedIn: false,
    checkingAuth: true,
    isResetted: false,
    isAdmin: false
  },
  companies: {
    isLoading: true,
    data: [],
    error: "",
  },
  changePassword: {
    isProcessing: false,
    error: "",
    password: "",
    confirmPassword: "",
  },
  resetPassword: {
    isProcessing: false,
    error: "",
    password: "",
    confirmPassword: "",
  },
  resetTempPassword: {
    isProcessing: false,
    error: "",
    password: "",
    confirmPassword: "",
  },
  forgotPassword: {
    isProcessing: false,
    error: "",
    mail: ""
  },
  changeEmail: {
    isProcessing: false,
    error: "",
    email: "",
    confirmEmail: "",
  },
}