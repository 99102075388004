import React, { useContext, useState } from "react";
import {
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";
// import validate from "../../../../shared/validation"
import { forgotPassword } from "../../../context/middleware";
import { Context } from "../../../context";
export default function usePassword(props) {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useContext(Context);

  const setState = (obj) => {
    dispatch({
      type: "SET_STATE",
      payload: obj,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    forgotPassword(email)(setState, state);
  };

  const render = () => (
    <div>
      <form>
        <Grid container>
          <Grid item xs={12}>
            <span
              onClick={() => setOpen(true)}
              className="float-end text-muted text-unline-dashed ms-1"
              style={{ marginBottom: "-20px", cursor: "pointer" }}
            >
              Forgot your password?
            </span>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogContent>
                <DialogContentText>
                  Enter your user account's verified email address and we will
                  send you a password reset link.
                </DialogContentText>
                <TextField
                  id="auth-forget-password-email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  // disabled={!validate.checkEmail(email)}
                  onClick={handleSubmit}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </form>
    </div>
  );


  return {
    render,
    setPasswordDialog: setOpen,
    isPasswordDialogOpen: open
  }
}
