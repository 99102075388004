import React from 'react';
import { Card } from 'react-bootstrap';


const CustomCard = ({ imageUrl, title, url, token }) => {

    return (
        <Card onClick={() =>
            window.open(
                url + "/loginrequest?token=" + token,
                "_self"
            )
        }
            className='dashboard-card'>
            <div className='text-center' >
                <Card.Img style={{ width: '150px', height: '100px', margin: '10px' }} variant="top" src={imageUrl} />
                <Card.Body>
                    <Card.Title >{title}</Card.Title>
                </Card.Body>
            </div>
        </Card>
    );
}

export default CustomCard;
