import React, { useEffect, useContext } from "react"
import { checkAuth } from "../../../context/middleware"
import { Context } from "../../../context"
import { Route, Switch, Redirect } from "react-router-dom"
import ResetTempPassword from "../ResetTempPassword"
import { protectedRoutes, unProtectedRoutes } from "../../../routes"
import Navbar from "../../../layout/Navbar"

function AuthWrapper() {
  const [state, dispatch] = useContext(Context)

  const setState = (obj) => {
    dispatch({
      type: "SET_STATE",
      payload: obj,
    })
  }

  useEffect(() => {
    checkAuth()(setState, state)
  }, [])

  console.log(state.login.isResetted)

  if (state.login.checkingAuth) return <p>Loading...</p>
  if (state.login.isResetted)
    return (
      <div>
        <ResetTempPassword />
      </div>
    )

  if (state.login.isLoggedIn) return (
    <Navbar>
      <Switch>
        {protectedRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              exact
              component={route.component}
            />
          );
        })}
        <Redirect from="/login" to="/" />
      </Switch>
    </Navbar>
  );

  return (
    <div>
      <Switch>
        {unProtectedRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
        <Redirect from="*" to={`/login`} />
      </Switch>
    </div>
  )
}

export default AuthWrapper
