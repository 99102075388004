import React, { useState } from "react";
import {
    Dialog,
    Typography,
    DialogContent,
    IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { CgNotes } from "react-icons/cg";
import { Link } from "react-router-dom";

function EmployeeNotes(props) {
    const { rowData } = props;
    const [open, setOpen] = React.useState(false);
    const [employeenotes, setEmployeeNotes] = useState(rowData?.employeenotes ?? "<p></p>");

    const handleClickOpen = () => {
        setOpen(true);
        setEmployeeNotes(rowData?.employeenotes)
    };
    const handleClose = () => {
        setOpen(false);
        setEmployeeNotes("");
    };

    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html ?? "<p></p>";
        return tmp.textContent || tmp.innerText || "";
    }

    const DialogTitle = withStyles((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    return (
        <div>
            {stripHtml(rowData?.employeenotes)?.length > 1 ? (
                <Link style={{ color: "red" }} onClick={handleClickOpen}><CgNotes size={20} /></Link>
            ) : (
                <Link style={{ color: "grey" }} onClick={handleClickOpen}><CgNotes size={20} /></Link>
            )}
            <Dialog
                onClose={handleClose}
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Notes for {rowData?.name}
                </DialogTitle>
                <DialogContent dividers>
                    {stripHtml(rowData?.employeenotes)}
                </DialogContent>
            </Dialog>
        </div>
    );
}


export default EmployeeNotes